<template>
  <v-container fluid class="mb-10 pr-15">
    <v-row>

<v-col md="6">

     <v-card elevation="2" outlined class="pa-5">
            <v-card-title> Sales Revenue</v-card-title>

            <p class="display-1 text--primary"></p>
            <p class="font-weight-bold pl-10">3462</p>

          </v-card>

</v-col>























      <!-- <v-row>
        <v-col>
          <h2 class="font-weight-bold">Welcome To Sell Overview</h2>
        </v-col>
        <div class="pt-5">
          <v-btn color="white" float-right height="30px" width="150px">
            <v-icon class="pr-2">mdi-calendar-today</v-icon>Last 30 days
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>

          <v-btn color="deep-purple accent-2" float-right height="30px">
            <v-icon color="white" class="pr-2"
              >mdi-file-document-edit-outline
            </v-icon>
            Reports
          </v-btn>
        </div>
      </v-row> -->



<!-- 
      <v-row>
        <v-col cols="6" sm="6">
          <v-img src="@/assets/sales1.png"></v-img>
        </v-col>


        <v-col cols="6" md="3" >
          
          <v-card elevation="2" outlined class="pa-5">
            <v-card-title> Active Subscriptions</v-card-title>

            <p class="display-1 text--primary"></p>
            <p class="font-weight-bold pl-10">6.9k</p>

            <div class="d-flex flex-row">
              <v-icon color="red">mdi-arrow-down</v-icon>
              <p class="mb-0 red--text">16.93</p>

              <v-icon color="red">mdi-percent</v-icon>
              <p class="mb-0 pl-10">Since Last Month</p>
              <v-icon></v-icon>
            </div>
          </v-card>
      
          <v-card elevation="2" outlined class="pa-5">
            <v-card-title> Avg Subscriptions</v-card-title>

            <p class="display-1 text--primary"></p>
            <p class="font-weight-bold pl-10">346.2</p>

            <div class="d-flex flex-row">
              <v-icon color="#1EE5D7">mdi-arrow-up</v-icon>
              <p class="mb-0 text--#1EE5D7">16.93</p>

              <v-icon color="#1EE5D7">mdi-percent</v-icon>
              <p class="mb-0 pl-10">Since Last Week</p>
              <v-icon></v-icon>
            </div>
          </v-card>
        </v-col>

        <v-col md="6">
          <v-card elevation="2" outlined class="pa-5">
            <v-card-title> Sales Overview</v-card-title>

            <div class="my-4 subtitle-1 mt-0">
              In 30 days sales of Product Subscriptions

              <v-btn color="#6A29FF" float-right>
                <v-icon color="white" @click="report"
                  >mdi-cloud-upload-outline</v-icon
                >Download Report
              </v-btn>
            </div>

            <div class="d-flex flex-row">
              <v-icon>mdi-currency-usd</v-icon>
              <p class="mb-0">829400.96</p>
              <v-spacer></v-spacer>
              <p class="mb-0 pl-10">1937 Subscriptions</p>
              <v-icon></v-icon>
            </div>
           
              <v-container fluid>
                <v-sparkline
                  :fill="fill"
                  :gradient="gradient"
                  :line-width="width"
                  :padding="padding"
                  :smooth="radius || false"
                  :value="value"
                  auto-draw
                  color="#D9CEFC"
                ></v-sparkline>

                <v-divider></v-divider>

                <v-row>
                  <v-col md="3">
                    <v-row class="fill-height" align="center">
                      <v-item-group v-model="gradient" mandatory>
                        <v-row>
                          <v-item
                            v-for="(gradient, i) in gradients"
                            :key="i"
                            v-slot:default="{ active, toggle }"
                            :value="gradient"
                          >
                            <v-card
                              :style="{
                                background:
                                  gradient.length > 1
                                    ? `linear-gradient(0deg, ${gradient})`
                                    : gradient[0],
                                border: '2px solid',
                                borderColor: active ? '#222' : 'white',
                              }"
                              width="30"
                              height="30"
                              class="mr-2"
                              @click.native="toggle"
                            ></v-card>
                          </v-item>
                        </v-row>
                      </v-item-group>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
           
          </v-card>
        </v-col>
      </v-row>

     
     
 -->

    </v-row>
  </v-container>
</template>


<script>
const gradients = [
  ["#222"],
  ["#42b3f4"],
  ["red", "orange", "yellow"],
  ["purple", "violet"],
  ["#00c6ff", "#F0F", "#FF0"],
  ["#f72047", "#ffd200", "#1feaea"],
];
export default {
  data: () => ({
    dialog: false,
    dialog2: false,
    question: "",
    ans: "",
    fill: true,
    value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
    width: 2,
    headers: [
      {
        text: "Order ID",
        align: "start",
        value: "id",
      },
      { text: "Price", value: "total_price" },
      { text: "Date", value: "ordered_date" },
      { text: "User ID", value: "user_id" },
      { text: "Order Status", value: "order_status" },
      { text: "Delivery Status", value: "delivery_status" },
    ],

    headers2: [
      {
        text: "product ID",
        align: "start",
        value: "id",
      },
      { value: "image" },
      { value: "title" },
      { value: "price" },
    ],
    value: "1020",
    orders: [],
    products: [],
    labels: ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"],
    value: [200, 675, 410, 390, 310, 460, 250, 240],
  }),

  methods: {
    initialize() {
      axios.get("Cart/allorders/").then((response) => {
        // console.log(response.data);
        this.orders = response.data.data;
      });
    },

    getproduct() {
      axios.get("product/display/5/").then((response) => {
        // console.log(response.data);
        this.products = response.data.data;
      });
    },
  },
  mounted() {
    this.initialize();
    this.getproduct();
  },
};
</script>


<style>
.v-sheet--offset {
  top: -10px;
  position: relative;
}
</style>